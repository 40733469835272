export default {
  returnFAQ: [
    { question: 'Gdzie mogę znaleźć numer mojego zamówienia?', answer: 'Twój numer zamówienia znajduje się na potwierdzeniu zamówienia, w prawym górnym rogu. Potwierdzenie otrzymałeś/-aś w wiadomości e-mail oraz w paczce.' },
    { question: 'Do kiedy mogę zarejestrować zwrot?', answer: 'Jeżeli chcesz zwrócić produkty, na zarejestrowanie zwrotu masz 14 dni od dnia otrzymania przesyłki.' },
    { question: 'Czy istnieje możliwość wymiany produktu?', answer: 'Niestety nie ma możliwości dokonania wymiany produktu. Zamiast tego zachęcamy do zgłoszenia zwrotu i złożenia nowego zamówienia. Na zgłoszenie zwrotu masz 14 dni od dnia otrzymania przesyłki.' },
    { question: 'Czy zwrot jest darmowy?', answer: 'Showroom.pl nie oferuje darmowych zwrotów. Koszty zwrotu są po stronie Klienta. Opłata za zwrot wynosi 9,90 zł za każdą paczkę, która zostanie odesłana. Kwota za zwrot zostanie odliczona od kwoty przelewu za zwrot zamówienia. Etykieta zostanie wysłana na podany adres email w ciągu 24h. Jeśli do przesyłki została dołączona etykieta na zwrotna, prosimy o jej użycie.' },
    { question: 'Kiedy otrzymam zwrot pieniędzy?', answer: 'Gdy tylko sklep odbierze Twój zwrot, otrzymasz e-mail z potwierdzeniem, że zwrot został przyjęty. Po tej informacji, możesz spodziewać się swoich środków w ciągu 1-5 dni roboczych, jeżeli płatność została dokonana kartą.' },
  ],
  trackingFAQ: [
    { question: 'Skąd będą wysłane zamawiane przeze mnie towary?', answer: 'Robiąc zakupy na stronie showroom.pl, dokonujesz zakupu w sklepach stacjonarnych zlokalizowanych na terenie całej Danii, Szwecji, Holandii, Włoch oraz Polski. W związku z tym towary wysłane są do Ciebie bezpośrednio ze sklepów stacjonarnych znajdujących się w całej Europie. Jeśli zamówisz produkty z kilku różnych sklepów, Twoje zamówienie przyjdzie w kilku przesyłkach. Może się też zdarzyć, że nie zostaną one dostarczone jednego dnia, lecz w ciągu kilku dni. ' },
    { question: 'Gdzie jest moja przesyłka?', answer: 'Przesyłki zagraniczne są wysyłane bezpośrednio ze sklepów stacjonarnych (w Danii, Szwecji, Włoch i Holandii) za pomocą różnych firm kurierskich, w zależności od sklepu. Z tego względu najlepiej do nas zadzwonić lub wysłać wiadomość korzystając z naszego formularza kontaktowego podając numer zamówienia, abyśmy mogli sprawdzić gdzie znajduje się Twoja przesyłka. Przesyłki nadawane z polskich butików wysyłane są za pomocą kuriera DPD. W tym przypadku otrzymasz mailem nr przesyłki lub link do jej śledzenia bezpośrednio na stronie DPD.' },
    { question: 'Jakie są dostępne sposoby dostawy oraz koszt?', answer: 'Przesyłki zagraniczne są wysyłane bezpośrednio ze sklepów stacjonarnych (w Danii, Szwecji, Włoch i Holandii) za pomocą różnych firm kurierskich, w zależności od sklepu.<br><br>Przesyłki nadawane z polskich butików wysyłane są za pomocą kuriera DPD.<br><br>Koszt przesyłki dla zamówień poniżej 300 zł wynosi 13,90 zł.<br>Przesyłka dla zamówień powyżej 300 zł jest darmowa.' },
    { question: 'W jaki sposób dostarczane jest moje zamówienie?', answer: 'Twoje zamówienie dostarczane jest pod podany przez Ciebie adres. Zamówione produkty otrzymasz łącznie lub w oddzielnych przesyłkach, w zależności od tego, skąd wysyłane są produkty. Przesyłki z polskich butików wysyłane są za pomocą DPD. Natomiast przesyłki z Danii, Szwecji, Włoch  oraz Holandii za pomocą innych wybranych przewoźników w danym kraju.' },
    { question: 'Ile wynosi czas dostawy?', answer: 'Aby dostawa mogła być zrealizowana jeszcze przed Świętami, sugerujemy złożenie zamówienia przed 18.12.2020.<br>Przewoźnicy deklarują wzmożony ruch z powodu szczytu sezonowego oraz Covid19.<br>Dokładamy wszelkich starań, aby wszystkie zamówienia były realizowane w zwykłym terminie.<br>Czas na przygotowanie produktów do wysyłki przez sklep wynosi do 8 dni roboczych. Od momentu nadania wysyłki przez sklep czas dostawy wynosi od 1 do 3 dni roboczych (w przypadku wysyłki ze sklepu w Polsce) oraz do 8 dni (w przypadku wysyłki z Danii, Szwecji, Włoch i Holandii).' },
  ],
  trackingProviders: [
    { name: 'Śledzenie DPD', description: 'Śledź swoja paczkę DPD.', url: 'https://gls-group.eu/PL/pl/sledzenie-paczek', logo: 'https://www.showroom.pl/static/graphics/dpd-logo.png' },
  ]
}
